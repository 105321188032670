.Input {
  border: 2px solid #A9B7C0;
  border-radius: 0;
  display: block;
  padding: .8rem;
  outline: none;
  width: 100%;
}

.Input:focus {
  border-color: var(--green);
}

.Input--error {
  border: 1px solid var(--red);
  border-width: 0.15rem;
}

.Input--error-message {
  color: var(--red);
  font-weight: 600;
  margin-top: 0.3rem;
}

.Input[type='range'] {
  background: transparent;
  border: none;
  padding: 0;
  width: 100%;
  -webkit-appearance: none;
}

.Input[type='range']::-webkit-slider-thumb {
  background: var(--blue);
  cursor: pointer;
  height: 1rem;
  margin-top: -0.35rem;
  width: 0.5rem;
  -webkit-appearance: none;
}

.Input[type='range']::-moz-range-thumb {
  background: var(--blue);
  cursor: pointer;
  height: 1rem;
  width: 0.5rem;
}

.Input[type='range']::-webkit-slider-runnable-track {
  background: var(--white);
  border: 1px solid var(--yellow);
  border-radius: 0.5rem;
  cursor: pointer;
  height: 7px;
  width: 100%;
}

.Input[type='range']::-moz-range-track {
  background: var(--white);
  border: 1px solid var(--yellow);
  border-radius: 0.5rem;
  cursor: pointer;
  height: 7px;
  width: 100%;
}

textarea.Input {
  border-radius: 0.5rem 0.5rem 0 0.5rem;
  min-height: 4rem;
}