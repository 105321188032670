.Button {
  border: 2px solid;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem 1.8rem;
  font-weight: 600;
  transition: 0.2s ease-in;
}

.small {
}

.large {
  padding: 0.8rem 6rem;
}

.disabled {
  background: var(--gray-200);
  cursor: default;
}

.squared {
  border-radius: 0;
}

.rounded {
  padding: 1rem 2rem;
  border-radius: 2rem;
}

.narrow {
  border-radius: 0.5rem;
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
}

.primary {
  background: var(--navy);
  border-color: transparent;
  color: var(--white);
}

.primary:hover {
  background: var(--navy-200);
}

.primary:focus {
  background: var(--navy);
}

.primary-inverse {
  background-color: var(--white);
  border-color: var(--white);
  color: var(--navy);
}

.primary-inverse:hover {
  background-color: var(--gray-300);
  border-color: var(--gray-300);
  color: var(--navy);
}

.primary-inverse:focus {
  background-color: var(--blue);
  border-color: var(--blue);
  color: var(--white);
}

.secondary {
  background-color: transparent;
  border-color: var(--navy);
  color: var(--navy);
}

.secondary:hover {
  background-color: transparent;
  border-color: var(--navy);
  color: var(--navy);
}

.secondary:focus {
  background-color: var(--navy);
  border-color: var(--navy);
  color: var(--white);
}

.secondary-inverse {
  background-color: var(--white);
  border-color: var(--navy);
  color: var(--navy);
}

.secondary-inverse:hover {
  background-color: var(--white);
  border-color: var(--navy);
  color: var(--navy);
}

.subtle {
  background-color: transparent;
  border-color: transparent;
  color: var(--blue);
}

.dark {
  background: var(--blue) 0% 0% no-repeat padding-box;
  color: var(--white);
}

.dark-hollow {
  background: var(--white) 0% 0% no-repeat padding-box;
  border: 2px solid var(--blue);
  color: var(--blue);
}

.icon.primary {
  box-shadow: 0px 3px 0.5rem rgba(247, 176, 107, 0.5);
}

.icon.secondary {
  box-shadow: 0px 3px 0.5rem rgba(104, 211, 217, 0.5);
}

.icon.tertiary {
  box-shadow: 0px 3px 0.5rem rgba(143, 157, 176, 0.4);
}
