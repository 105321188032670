.Footer {
  width: 100%;
  background-color: var(--navy);
  color: var(--white);
  /* margin-top: 1rem; */
  padding: 2rem 0;
}

.Footer--content {
  align-items: center;
  display: flex;
  flex-flow: wrap;
  flex-direction: column;
  justify-content: space-between;
}

.Footer--content > * {
  line-height: 1rem;
  display: inline-block;
}

.Footer--content__start {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 64em) {
  .Footer--content {
    flex-direction: row;
  }

  .Footer--content__start {
    display: flex;
    flex-direction: row;
  }

  .Footer--content__start .Footer--content__start-link::before {
    content: '\00a0|\00a0';
  }
}
