.c-side-drawer__container {
    background-color: var(--white);
    height: 100%;
    max-width: 500px;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateX(100%);
    transition: transform 500ms cubic-bezier(0.23, 1, 0.32, 1) .15s;
    width: 80%;
    z-index: 99;
}

.c-side-drawer__inner {
    align-items: center;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
}

.c-side-drawer__close {
    align-items: center;
    background-color: var(--white);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 2.2rem;
    justify-content: center;
    margin-bottom: 2rem;
    margin-right: auto;
    outline: none;
    padding: .6rem;
    transition: 500ms cubic-bezier(0.23, 1, 0.32, 1) .15s;
    width: 2.2rem;
}

.c-side-drawer__close:hover {
    background-color: #F4F4F4;
}

.c-side-drawer__close svg {
    fill: var(--color);
    max-width: 100%;
}

.c-side-drawer__container--expanded {
    box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 1px, rgb(9 30 66 / 31%) 0px 0px 20px -6px;
    transform: translateX(0);
    transition: transform 500ms cubic-bezier(0.23, 1, 0.32, 1) .25s;
}