.SiteMenu {
  position: fixed;
  right: 0;
  z-index: 100;
  width: 16rem;
}

.SiteMenu--activator {
  position: absolute;
  right: 1rem;
}

.SiteMenu--LogoutButton {
  display: block;
  margin: 0 auto;
}

.SiteMenu--Icon {
  transform: translateY(3px);
  display: inline-block;
  margin-right: 1rem;
}


.SiteMenu__ul {
  list-style: none;
}

.SiteMenu__li {
  align-items: center;
  display: flex;
}

.SiteMenu__a {
  border-bottom: 2px solid transparent;
  display: flex;
  flex: 1;
  text-decoration: inherit;
  color: var(--blue);
  font-family: europa, sans-serif;
  font-weight: 400;
  padding: 1.25rem 0;
  transition: .15s ease-in-out;
}

.SiteMenu__a:hover {
  border-color: 2px solid var(--blue);
}
