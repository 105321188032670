.Header {
  background-color: var(--white);
  border-bottom: 4px solid var(--navy);
}

.Header--Container {
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
}

.Header--Logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Header--help .Icon--wrapper {
  border: 5px solid var(--white);
  background-color: var(--blue);
}
